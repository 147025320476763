.search-bar {
    position: relative; /* Add position relative to the search bar container */
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .search-input {
    flex: 1;
    border: none;
    border-radius: 30px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #f2f2f2;
    transition: background-color 0.3s ease;
  }
  
  .search-input:focus {
    background-color: #e6e6e6;
    outline: none;
  }
  
  .search-results {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    position: absolute; /* Add position absolute to the search results */
    z-index: 10; /* Set the desired z-index value */
  }
  
  .search-results li {
    padding: 8px;
    background-color: #f2f2f2;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .search-results li:hover {
    background-color: #e6e6e6;
    color: #000;
  }
  